<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--8">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('criticality')}}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button type="default" size="sm" @click="updateCritical" :disabled="load.critical">{{tt('save')}} <span v-if="load.critical"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div>
                                </div>
                            </div>
                            <el-table class="table-responsive table" :data="criticals" header-row-class-name="thead-light" v-if="!loadTable.critical">
                                <el-table-column :label="tt('criteria')" sortable min-width="50px" prop="indicator">
                                    <template v-slot="{row}">
                                        <el-input v-model="criticals[criticals.map(function(d) { return d['id'];}).indexOf(row.id)].indicator"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('description')" sortable min-width="300px" prop="description">
                                    <template v-slot="{row}">
                                        <el-input type="textarea" v-model="criticals[criticals.map(function(d) { return d['id'];}).indexOf(row.id)].description"></el-input>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <page-loading v-else />
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('lead_time')}}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button type="default" size="sm" @click="updateLeadTime" :disabled="load.lead">{{tt('save')}} <span v-if="load.lead"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div>
                                </div>
                            </div>
                            <el-table class="table-responsive table" :data="leads" header-row-class-name="thead-light" v-if="!loadTable.lead">
                                <el-table-column :label="tt('criteria')" sortable min-width="60px" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].indicator"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('operation')" sortable min-width="60px" prop="page">
                                    <template v-slot="{row}">
                                        <el-select v-model="leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].operation">
                                            <el-option v-for="o in operations" :key="o.id" :label="o.operation" :value="o.operation" :selected="o.operation == leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].operation"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('from')" sortable min-width="40px" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].from" type="number"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('to')" sortable min-width="40px" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].to" type="number"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('description')" sortable min-width="130px" prop="visitors">
                                     <template v-slot="{row}">
                                        <el-input type="textarea" v-model="leads[leads.map(function(d) { return d['id'];}).indexOf(row.id)].description"></el-input>
                                    </template>
                                </el-table-column>>
                            </el-table>
                             <page-loading v-else />
                        </div>
                    </div>
                </div>

                 <div class="row mt-2">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('usage_value')}}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button type="default" size="sm" @click="updateUsageValue" :disabled="load.usage">{{tt('save')}} <span v-if="load.usage"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div>
                                </div>
                            </div>
                            <el-table class="table-responsive table" :data="usages" header-row-class-name="thead-light" v-if="!loadTable.usage">
                                <el-table-column :label="tt('criteria')" sortable min-width="60" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].indicator"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('operation')" sortable min-width="60px" prop="page">
                                    <template v-slot="{row}">
                                        <el-select v-model="usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].operation">
                                            <el-option v-for="o in operations" :key="o.id" :label="o.operation" :value="o.operation" :selected="o.operation == usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].operation"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('from')" sortable min-width="40px" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].from" type="number"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('to')" sortable min-width="40px" prop="page">
                                    <template v-slot="{row}">
                                        <el-input v-model="usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].to" type="number"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="tt('description')" sortable min-width="130px" prop="visitors">
                                     <template v-slot="{row}">
                                        <el-input type="textarea" v-model="usages[usages.map(function(d) { return d['id'];}).indexOf(row.id)].description"></el-input>
                                    </template>
                                </el-table-column>>
                            </el-table>
                            <page-loading v-else />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/helpers/api';
import CriteriaService from '@/services/rcs/abc/criteria.service'
export default {
    data(){
        return{
            operations:[
                {
                    id:1,
                    operation:'>',
                },
                {
                    id:2,
                    operation:'<',
                },
                {
                    id:3,
                    operation:'>=',
                },
                {
                    id:4,
                    operation:'<=',
                },
                {
                    id:5,
                    operation:'='
                }
            ],
            criticals:[],
            usages:[],
            leads:[],
            load:{
                lead:false,
                usage:false,
                critical:false,
            },
            loadTable:{
                lead:false,
                usage:false,
                critical:false,
            }
        }
    },
    mounted(){
        this.getCriticality()
        this.getLeadTime()
        this.getUsageValue()
    },
    methods:{
        updateCritical(){
            this.load.critical = true;
            let context = this;
            let dataCritical = {
                'id':[],
                'indicator': [],
                'description' : [],
            }
            for(var i = 0;i < this.criticals.length;i++){
                if(this.criticals[i].indicator == '' || this.criticals[i].description == ''){
                    this.$notify({
                        message: context.tt('not_yet_complete'),                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    this.load.critical = false;
                    return;
                }else{
                    dataCritical.id.push(this.criticals[i].id)
                    dataCritical.indicator.push(this.criticals[i].indicator)
                    dataCritical.description.push(this.criticals[i].description)
                }
            }

            if(dataCritical.id.length > 0){
                Api(context, CriteriaService.update_criticality(dataCritical)).onSuccess((response) =>{
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.critical = false
                }).onError((err) =>{
                    context.$notify({
                        message: err.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.critical = false
                }).onFinish(() =>{
                    context.getCriticality();
                }).call()
            }
        },
        getCriticality(){
            let context = this;
            this.loadTable.critical = true;
            Api(context, CriteriaService.getCriticality()).onSuccess((response) => {
                context.criticals = response.data.data
                context.loadTable.critical = false
            }).onError((err) =>{
                context.criticals = []
                context.loadTable.critical = false
            }).call()
        },
        updateLeadTime(){
            this.load.lead = true;
            let context = this;
            let dataLeads = {
                'id':[],
                'indicator': [],
                'operation': [],
                'from': [],
                'to': [],
                'description' : [],
            }
            for(var i = 0;i < this.leads.length;i++){
                if(this.leads[i].indicator == '' || this.leads[i].operation == '' || this.leads[i].from == '' || this.leads[i].to == '' || this.leads[i].description == ''){
                    this.$notify({
                        message: context.tt('not_yet_complete'),                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    this.load.lead = false;
                    return;
                }else{
                    dataLeads.id.push(this.leads[i].id)
                    dataLeads.indicator.push(this.leads[i].indicator)
                    dataLeads.operation.push(this.leads[i].operation)
                    dataLeads.from.push(this.leads[i].from)
                    dataLeads.to.push(this.leads[i].to)
                    dataLeads.description.push(this.leads[i].description)
                }
            }

            if(dataLeads.id.length > 0){
                Api(context, CriteriaService.update_leadtime(dataLeads)).onSuccess((response) =>{
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.lead = false
                }).onError((err) =>{
                    context.$notify({
                        message: err.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.lead = false
                }).onFinish(() =>{
                    context.getLeadTime();
                }).call()
            }
        },
        getLeadTime(){
            let context = this;
            this.loadTable.lead = true;
            Api(context, CriteriaService.getLeadTime()).onSuccess((response) => {
                context.leads = response.data.data
                context.loadTable.lead = false
            }).onError((err) =>{
                context.leads = []
                context.loadTable.lead = false
            }).call()
        },
        updateUsageValue(){
            this.load.usage = true;
            let context = this;
            let dataUsages = {
                'id':[],
                'indicator': [],
                'operation': [],
                'from': [],
                'to': [],
                'description' : [],
            }
            for(var i = 0;i < this.usages.length;i++){
                if(this.usages[i].indicator == '' || this.usages[i].operation == '' || this.usages[i].from == '' || this.usages[i].to == '' || this.usages[i].description == ''){
                    this.$notify({
                        message: context.tt('not_yet_complete'),                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    this.load.usage = false;
                    return;
                }else{
                    dataUsages.id.push(this.usages[i].id)
                    dataUsages.indicator.push(this.usages[i].indicator)
                    dataUsages.operation.push(this.usages[i].operation)
                    dataUsages.from.push(this.usages[i].from)
                    dataUsages.to.push(this.usages[i].to)
                    dataUsages.description.push(this.usages[i].description)
                }
            }

            if(dataUsages.id.length > 0){
                Api(context, CriteriaService.update_usagevalue(dataUsages)).onSuccess((response) =>{
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.usage = false
                }).onError((err) =>{
                    context.$notify({
                        message: err.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.load.usage = false
                }).onFinish(() =>{
                    context.getUsageValue();
                }).call()
            }
        },
        getUsageValue(){
            let context = this;
            this.loadTable.usage = true;
            Api(context, CriteriaService.getUsageValue()).onSuccess((response) => {
                context.usages = response.data.data
                context.loadTable.usage = false
            }).onError((err) =>{
                context.usages = []
                context.loadTable.usage = false
            }).call()
        },
    }
}
</script>