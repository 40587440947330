import Service from "@/helpers/service";


export default{
    update_criticality(params){
        return Service().post('ABCCriteria/criticality/update', params)
    },
    update_leadtime(params){
        return Service().post('ABCCriteria/leadtime/update', params)
    },
    update_usagevalue(params){
        return Service().post('ABCCriteria/usagevalue/update', params)
    },
    getCriticality(){
        return Service().get('ABCCriteria/criticality')
    },
    getLeadTime(){
        return Service().get('ABCCriteria/leadtime')
    },
    getUsageValue(){
        return Service().get('ABCCriteria/usagevalue')
    },
    // get(){
    //     return Service().get('ABCCriteria')
    // }
}